/* 
AW_RED       = #f57179
AW_BLUE      = #8f8798
AW_YELLOW    = #feedba   
AW_LIGHTBLUE = #e6e6e6
AW_GRAY      = #8c8a97
*/

/* Main App styles */
.App {
  max-width: 40rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
}


header {
  height: 80px;
  padding: 0 1em 0 1em;
}

footer {
  height: 25px;
  font-size: small;
  font-weight: lighter;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

footer a {
  color: #333;
  text-decoration: none;
}

main {
  height: calc(-25px + 100vh);
  padding: 0 1em 0 1em;
  display: flex;
  flex-direction: column;
}

body {
  background-color: #feedba;
}

body a {
  text-decoration: none;
}

.hot {
  color: #f57179;
  font-weight: bold;
}

.card {
  padding: 0 1em 1em 1em;
}

#quitButton {
  display: block;
  margin: auto;
  position: absolute;
  top: 0.1em;
  right: -0.5em;
}

.screen-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.form-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5em;
}

.form-row label {
  margin-right: 10px;
  font-size: 1.2em;
  min-width: 56px;
}

.horizontal-control {
  font-size: 1.2em;
  border-radius: 0.2em;
  max-width: 100%;
}

.turns {
  background-color: white;
}

.nav-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.6em;
  text-transform: uppercase;
  height: 2em;
  border-radius: 0.2em;
  background-color: #f57179;
  color: white;
  width: 80%;
  margin: 0.5em auto 0.5em auto;
}

.pic-small {
  height: 50px;
  width: 50px;
}

.pic-medium {
  height: 100px;
  width: 100px;
}

.pic-large {
  height: 150px;
  width: 150px;
}
.error {
  font-weight: bold;
  color: red;
}

.button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 0.5em;
}

.button {
  display: flex;
  flex-direction: column;
  width: 10em;
  border: none;
  border-radius: 0.2em;
  height: 2em;
  font-weight: bold;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}

.button:disabled {
  color: #666;
  background-color: white;
  border: 1px dotted #666;
  text-decoration: line-through;
  cursor: not-allowed;
}

.danger {
  background-color: red;
  color: white;
}

.success {
  background-color: green;
  color: white
}

.secondary {
  background-color: #8f8798;
  color: #feedba;
}

.primary {
  background-color: #feedba;
  color: #8f8798;
}

.m-0 {
  margin: 0;
}

.m-auto {
  margin: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-2 {
  margin-top: 0.2em;
}

.mt-4 {
  margin-top: 0.4em;
}

.mt-6 {
  margin-top: 0.6em;
}

.mt-8 {
  margin-top: 0.8em;
}

.mt-10 {
  margin-top: 1em;
}

.mt-20 {
  margin-top: 2em;
}

.mt-30 {
  margin-top: 3em;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-2 {
  margin-bottom: 0.2em;
}

.mb-4 {
  margin-bottom: 0.4em;
}

.mb-6 {
  margin-bottom: 0.6em;
}

.mb-8 {
  margin-bottom: 0.8em;
}

.p-0 {
  padding: 0;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-upper {
  text-transform: uppercase;
}

.aw-red {
  color:#f57179;
}

.aw-blue {
  color: #8f8798;
}

.br-2 {
  border-radius: 0.2em;
}

.fs-12 {
  font-size: 1.2em;
}

.fs-14 {
  font-size: 1.4em;
}

.fs-16 {
  font-size: 1.6em;
}

.text-bold {
  font-weight: bold;
}

.ml-2 {
  margin-left: 0.2em;
}

.ml-4 {
  margin-left: 0.4em;
}

.p-auto {
  padding: auto;
}

.pl-2 {
  padding-left: 0.2em;
}

.pl-4 {
  padding-left: 0.4em;
}

.pl-6 {
  padding-left: 0.6em;
}

.pl-8 {
  padding-left: 0.8em;
}

.pl-10 {
  padding-Left: 1em;
}

.pr-2 {
  padding-right: 0.2em;
}

.pr-4 {
  padding-right: 0.4em;
}

.pr-6 {
  padding-right: 0.6em;
}

.pr-8 {
  padding-right: 0.8em;
}

.pr-10 {
  padding-right: 1em;
}


.pointer {
  cursor: pointer;
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.col {
  max-width: 100%;
  flex-basis: 0;
  flex-grow: 1;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border: 0;
}

/* Screen Header styles */
.screen-header {
  display: flex;
  flex-direction: row;
  height: fit-content;
  margin-top: 2em;
  margin-bottom: 1em;
  position: sticky;
  top: 0;
  background-color: white;
}

.sh-left {
  width: 50px;
}

.sh-main {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
}

.sh-main h2 {
  margin: 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  color:#8c8a97;
  font-size: 2em;
}
.sh-right {
  width: 50px;
}

/* About and High Scores pages */
.vert-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto 0 auto 0;
}

ul.score-list {
  list-style: none;
  text-align: left;
}

ul.score-list li {
  margin-bottom: 0.7em;
}

.about-main h3 {
  margin: 0
}

.about-main p {
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.back-arrow {
  max-width: 100%;
  max-height: 100%;
}

/* Home page */

#home-header {
  column-gap: 1em;
  flex-basis: 1;
}

#hh-left {
  text-align: right;
}

#hh-right {
  text-align: left;
}

.hh-side {
  color: #8f8798;
  font-size: 3em;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.hh-middle {
  max-width: fit-content;
}

.hh-middle img {
  max-width: 120px;
}

div#home-screen {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

#main-menu {
  text-align: center;
  flex-basis: 2;
}

#main-menu li {
  margin-bottom: 2em;
}

.main-menu-button {
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  font-size: 2em;
  max-width: 10em;
  margin-left: auto;
  margin-right: auto;
  
  color: #f57179;
}

.highlight {
  font-weight: bold;
}

/* Game screen main */
.game-container {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
}

.game-main {
  display: flex;
  flex-direction: column;
  height: calc(-95px + 100vh);
}

.game-tab {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 5px 0 5px 0;
  height: 60px;
  border-radius: 0.2em;
  background-color: #e6e6e6;
  position: absolute;
  bottom: 0;
  width: 100%;
  flex-grow: 1;
}

.help-button {
  position: absolute;
  right: 5px;
  top: -25px;
  cursor: pointer;
  color: dodgerblue;
  font-size: 1.2em;
}

.game-tab img {
  max-width: 50px;
  max-height: 50px;
}

/* City screen */

.city-main {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.city-heading {
  text-align: center;
  font-size: 2em;
  color: #8c8a97;
  text-transform: uppercase;
  margin: 0 0 1rem 0;
}

.city-select {
  background-color: #f57179;
  color: white;
  font-size: 1.2em;
  border-radius: 0.2em;
  text-align: center;
  max-width: 12em;
  border: none;
  padding: 0.5em;
  cursor: pointer;
}

.city-select-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;

}

.message-board {
  background-color: #feedba;
  text-align: center;
  flex-basis: 100%;
  margin: 0 auto 1em auto;
  width: 90%;
}

.message-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Art list */
.bare-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.art-list-ul {
  list-style: none;
  padding: 0;
}

.portfolio-ul {
  list-style: none;
}

.art-list-li {
  margin-bottom: 0.5em;
  
}

.art-list-li:hover {
  border:1px #f57179 solid;
  padding: 0.3em;
}

.art-list-li p {
  margin: 0;
  color: #8c8a97
}

.art-list-h3 {
  text-transform: uppercase;
  font-size: 1.6em;
}

/* Game screen Common */
.tab-main {
  overflow-y: auto;
  margin-bottom: 1em;
}

.tab-container {
  display: flex;
  flex-direction: column;
}

/* Collector screen */



.npc-name {
  font-size: 1.8em;
  color: #8c8a97;
  text-transform: uppercase;
}

.npc-dialog {
  display: flex;
  flex-direction: row;
  column-gap: 0.5em;
  margin-top: 1em;
  height: 50px;
}

.npc-dialog > .dialog-text {
  padding: 0.5em;
  border: 1px #e6e6e6 solid;
  border-radius: 0.2em;
  flex-basis: 100%;
}

.collector-bio {
  display: flex;
  flex-direction: row;
  column-gap: 1em;
  height: 40%;
}

.collector-list-container .art-list {
  height: 50%
}

.offer-input {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  column-gap: 0.5em;
  height: 2em;
}

.offer-input input {
  font-size: 1.2em;
  padding-left: 0.5em;
  height: 100%;
  border: 1px #e6e6e6 solid;
  border-radius: 0.2em;
  min-width: 0;
}

.offer-input button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f57179;
  color: white;
  font-size: 1.2em;
  border: none;
  cursor: pointer;
  min-width: fit-content;
  height: 100%;
}

.auction-messages {
  list-style: none;
  color: #8c8a97;
}

.art-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-right: 1em;
}

.art-info p {
  text-align: right;
}

.shop-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.game-header {
  height: fit-content;
  padding-top: 10px;
  margin-bottom: 1.5em;
  position: relative;
}

.gh-right h2 {
  color: #8c8a97;
  font-size: 2em;
}

.gh-right {
  flex-basis: 100%;
  white-space: nowrap;
}

.game-info {
  margin: 0em 1em;
  font-size: 0.8em;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.game-info div {
  margin-right: 1em;
  margin-bottom: 0.2em;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.gh-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 50px;
}

img#header-logo {
  height: 50px;
  width: 50px;
}
img#back-arrow {
  width: 50px;
  cursor: pointer;
  margin-top: 4px;
}

#quit-button {
  position: absolute;
  top: 5px;
  right: -14px;
}

#new-game-form {
  width: 80%;
  margin: 3em auto 3em auto;
}

.active {
  background-color: #f57179;
  border-radius: 10px;
  padding: 5px;
}

.game-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}



.skyline {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1em;
}

.skyline img {
  max-width: 100%;
}

.modal-background {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);

}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 34rem;
}

.tour-content p {
  text-align: left;
  margin-top: 0;
  margin-bottom: 2%;
}

.tour-content p:last-child {
  margin: 0;
}

.tour-buttons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.tour-skip {
  display: flex;
  flex-direction: row;
}


.tt-bottom::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
}

.tt-left {
  top: 50%;
  right: calc(100% + 10px);
}

.tt-left::after {
  content: "";
  position: absolute;
  bottom: 50%;
  left: 100%;
  margin-right: -5px;
  border-width: 10px;
  border-style: solid;
  border-color:  transparent transparent transparent #555;
}

.tt-right {
  top: 50%;
  left: calc(100% + 10px);
}

.tt-right::after {
  content: "";
  position: absolute;
  bottom: 50%;
  left: -10px;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color:  transparent  #555 transparent transparent;
}

.tour-button-col {
  display: flex;
  flex-direction: column-reverse;
  min-height: 4em;
}

.tour-highlight {
  /* border: 2px solid #f57179; */
  border: 2px solid yellow;
  border-radius: 0.2em;
}

/* Small Screen styles */
@media screen and (max-width: 640px) {
  .App {
    width: 100%;
    margin: 0;
  }

  main  {
    padding: 0 0.5em;
  }

  #quitButton {
  position: absolute;
  top: 0.2em;
  right: -0.5em;
  }
}